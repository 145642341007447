import './App.css';
import logo from "./images/logo.png"

function App() {
  return (
    <div className="App">
      <Maintenance/>
    </div>
  );
}

function Maintenance(){
  return(
    <div id='Maintenance'>
      <img src={logo} style={{width:"200px"}}></img>
      <h1>We are under maintenance!</h1>
      <span>We will be back soon, stay tuned!</span>
    </div>
  )
}

export default App;
